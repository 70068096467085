import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { usePageContext } from '../context/PageContext';
import { Locale } from '../utils/types';
import { ImageFromUrlProps } from '../components/ImageFromUrl';
import { ImageRasterProps } from '../components/ImageRaster';
import { RichTextData } from '../components/RichText';
import { TitleAreaWithImage } from '../components/TemplateHelpers/2-TitleAreaWithImage';
import { ContentArea } from '../components/TemplateHelpers/3-ContentArea';

export type BlogPostQuery = {
  contentfulPageBlogPost: {
    slug: string;
    title: string;
    subtitle?: string;
    language: Locale;
    createdAt: string;
    authors?:
      | null
      | {
          name: string;
        }[];
    authorsOther?: null | string[];
    image: {
      gatsbyImageData?: ImageRasterProps['image'];
      fixed?: { src: ImageFromUrlProps['image'] };
    };
    content?: RichTextData;
    showTableOfContents?: boolean;
  };
};

const BlogPost = ({ data }: PageProps<BlogPostQuery>): ReactElement => {
  const { previousPath, nextPath } = usePageContext();
  const {
    title,
    subtitle,
    language,
    createdAt,
    authors,
    authorsOther,
    image,
    content,
    showTableOfContents,
  } = data.contentfulPageBlogPost;
  const authorNames = authors
    ?.map((author) => author.name)
    .concat(authorsOther || []);

  return (
    <>
      <TitleAreaWithImage
        variant={'blogPost'}
        title={title}
        subtitle={subtitle}
        blogPostData={{
          language: language,
          createdAt: createdAt,
          authorNames: authorNames,
          image: image,
        }}
      />
      <ContentArea
        variant={'blogPost'}
        content={content}
        showTableOfContents={showTableOfContents}
        previousPath={previousPath}
        nextPath={nextPath}
      />
    </>
  );
};

export default BlogPost;

export const query = graphql`
  query ($id: String!) {
    contentfulPageBlogPost(id: { eq: $id }) {
      ... on ContentfulPageBlogPost {
        ...BlogPost
      }
    }
  }
`;
